import React, { useState, useEffect } from 'react';
import { FaWhatsapp } from 'react-icons/fa'; // Import WhatsApp icon
import './LandingPage.css'; // Import the CSS file
import img1 from './1.png'; // Import the image correctly

const LandingPage = () => {
  const [promoCodeVisible, setPromoCodeVisible] = useState(false);
  const [buttonCodeVisible, setbuttonCodeVisible] = useState(true);
  const [whatsappButtonDisabled, setWhatsappButtonDisabled] = useState(true);

  useEffect(() => {
    const loadFacebookPixel = () => {
      (function(f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function() {
          n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = '2.0';
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
      })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');

      window.fbq = window.fbq || function() {};
      window.fbq('init', '295669516421830'); // Replace with your Pixel ID
      window.fbq('track', 'PageView');
    };

    loadFacebookPixel();
  }, []);

  const handleWhatsAppClick = () => {
    if (window.fbq) {
      window.fbq('track', 'Lead');
    }
    window.location.href = 'https://api.whatsapp.com/send?phone=60183943250&text=PromosiSlimmingPanties';
  };

  const handleClaimPromoClick = () => {
    setPromoCodeVisible(true);
    setWhatsappButtonDisabled(false);
    setbuttonCodeVisible(false);
  };

  return (
    <div className="landing-container">
      <img
        src="https://png.pngtree.com/png-clipart/20230710/ourmid/pngtree-flowers-png-image_7506581.png"
        alt="Aizira Logo"
        className="landing-logo"
      />
      <h1 className="landing-heading">
        Aizira Malaysia <span className="superscript">HQ</span>
      </h1>
      <img src={img1} alt="Promo" className="promo-image" /> {/* Display 1.png image */}
      <p className="landing-text"></p>
      {promoCodeVisible && (
        <button 
          className="landing-button"
          onClick={handleWhatsAppClick}
          disabled={whatsappButtonDisabled}
        >
          WhatsApp Sekarang <FaWhatsapp /> {/* WhatsApp icon */}
        </button>
      )}
      {promoCodeVisible && (
        <h3 className="landing-heading1">
          PromoCode: AIZIRA-FM24
        </h3>
      )}
      {promoCodeVisible && (
        <h3 className="landing-heading1">
          -RM30.00 Diskaun Untuk Anda 
        </h3>
      )}
      {buttonCodeVisible && (
        <button className="landing-button" onClick={handleClaimPromoClick}>
          Claim Promo Kod
        </button>
      )}
    </div>
  );
};

export default LandingPage;
